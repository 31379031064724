/* eslint-disable no-param-reassign */
import axios, { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { BASE_URL } from '../helpers/constants';
import { ErrorResponse } from '../types/common';

const http = axios.create({
  baseURL: `${BASE_URL}/api`,
});

http.interceptors.response.use(undefined, (error: AxiosError) => {
  const expectedError =
    error.response && error.response.status >= 400 && error.response.status < 500;

  if (!expectedError) {
    // eslint-disable-next-line no-console
    console.log('Unexpected Error', error);
    toast.error('An Unexpected Error Occured, please try again later');
  }

  if (
    error.response &&
    error.response.config?.method === 'get' &&
    error.response.status > 400 &&
    error.response.status < 409
  ) {
    const err = error.response.data as ErrorResponse;

    if (err.error !== 'Invalid or Expired Token') {
      toast.error(`${err.error}`);
    }
  }

  return Promise.reject(error);
});

export function setJwt(jwt: string) {
  if (jwt) {
    http.defaults.headers.common.authorization = `Bearer ${jwt}`;
  } else {
    delete http.defaults.headers.common.authorization;
  }
}

export default http;
