import React from 'react';

export type LoadingContextType = readonly [boolean, React.Dispatch<React.SetStateAction<boolean>>];

const LoadingContextDefaultValues = [false, () => null] as const;

const LoadingContext = React.createContext<LoadingContextType>(LoadingContextDefaultValues);

interface IProps {
  children: React.ReactNode;
}

export function LoadingContextProvider({ children }: IProps) {
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const contextValues = React.useMemo(() => [loaded, setLoaded] as const, [loaded, setLoaded]);

  return <LoadingContext.Provider value={contextValues}>{children}</LoadingContext.Provider>;
}

export function useLoading() {
  const values = React.useContext<LoadingContextType>(LoadingContext);
  return values;
}

export default LoadingContext;
