import jwtDecode from 'jwt-decode';
import http, { setJwt } from './httpService';
import { SuccessMessage } from '../types/common';
import {
  ILogin,
  ILoginResponse,
  JWTObject,
  ResetPasswordType,
  RegistrationType,
} from '../types/models/auth';

const tokenKey = 'token';

export function setToken(token: string) {
  localStorage.setItem(tokenKey, token);
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export async function login(loginDetails: ILogin) {
  const { data } = await http.post<ILoginResponse>('/user/login', loginDetails);
  setToken(data.authorization);
  setJwt(data.authorization);
}

export async function adminLogin(loginDetails: ILogin) {
  const { data } = await http.post<ILoginResponse>('/admin/login', loginDetails);
  setToken(data.authorization);
  setJwt(data.authorization);
}

export async function signup(signupDetails: RegistrationType) {
  const formData = new FormData();

  Object.entries(signupDetails).forEach(([key, value]) => {
    if (value) formData.append(key, value);
  });

  const { data } = await http.post<SuccessMessage>('/user/signup', formData);

  return data;
}

export async function forgetPasswordRequest(email: string) {
  const { data } = await http.get<SuccessMessage>(`/user/reset_request/${email}`);
  return data;
}

export async function resetPassword(
  payload: Omit<ResetPasswordType, 'confirmPassword'>,
  token: string
) {
  const { data } = await http.post<SuccessMessage>('/user/reset_password', payload, {
    headers: {
      token,
    },
  });

  return data;
}

export async function verifyEmail(params: [string, string]) {
  const token = params[1];

  const { data } = await http.get<SuccessMessage>('/user/verify_email', {
    headers: {
      token,
    },
  });

  return data;
}

verifyEmail.key = 'verifyEmail';

export async function resendEmail(email: string) {
  const { data } = await http.get<SuccessMessage>(`/user/resend_verification_email/${email}`);

  return data;
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser(): JWTObject | null {
  try {
    const jwt = localStorage.getItem(tokenKey);
    if (jwt) {
      const user: JWTObject = jwtDecode(jwt);
      return user;
    }
    return null;
  } catch (ex) {
    return null;
  }
}
