import React from 'react';
import { getCurrentUser, getJwt } from '../services/authService';
import { setJwt } from '../services/httpService';
import { JWTObject } from '../types/models/auth';

export interface AuthContextType {
  auth: JWTObject | null;
  setAuth: React.Dispatch<React.SetStateAction<JWTObject | null>>;
}

const authContextDefaultValues = {
  auth: null,
  setAuth: () => null,
};

const AuthContext = React.createContext<AuthContextType>(authContextDefaultValues);

interface IProps {
  children: React.ReactNode;
}

export function AuthContextProvider({ children }: IProps) {
  const [auth, setAuth] = React.useState<JWTObject | null>(null);

  React.useEffect(() => {
    const currentUser = getCurrentUser();
    setAuth(currentUser);
    const jwt = getJwt();
    if (jwt) setJwt(jwt);
  }, [setAuth]);

  const contextValues = React.useMemo(() => ({ auth, setAuth }), [auth, setAuth]);

  return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const { auth, setAuth } = React.useContext<AuthContextType>(AuthContext);
  return { auth, setAuth };
}

export default AuthContext;
