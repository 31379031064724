import type { AppProps } from 'next/app';
import { Toaster } from 'react-hot-toast';
import { AuthContextProvider } from '../contexts/AuthContext';
import { LoadingContextProvider } from '../contexts/LoadingContext';

import '@fontsource/poppins';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/reset.scss';
import '../styles/globals.scss';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <div>
      <Toaster position="bottom-center" toastOptions={{ duration: 5000 }} />
      <AuthContextProvider>
        <LoadingContextProvider>
          <Component {...pageProps} />
        </LoadingContextProvider>
      </AuthContextProvider>
    </div>
  );
}

export default MyApp;
