export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const FILE_SIZE_LIMIT = 1000000;
export const FILE_SIZE_STRING = '1MB';

export const screenSizes = {
  sm: '578px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export const modalWidths = {
  xs: '360px',
  sm: '480px',
  md: '748px',
  lg: '972px',
  xl: '1180px',
};

export const COLORS = {
  YELLOW: '#FCBC14',
  GREEN: '#9ACF61',
  PRIMARY: '#66B3B3',
  SECONDARY: '#006666',
  RED: '#FF2727',
  WHITE: '#FFF',
};

export const banksList = [
  { label: 'Allied Bank limited', value: 'Allied Bank limited' },
  { label: 'Al-Baraka Bank Pakistan Limited', value: 'Al-Baraka Bank Pakistan Limited' },
  { label: 'Askari Bank Limited', value: 'Askari Bank Limited' },
  { label: 'Bank Al-Falah Limited', value: 'Bank Al-Falah Limited' },
  { label: 'Bank Al-Habib Limited', value: 'Bank Al-Habib Limited' },
  { label: 'Bank of China Limited', value: 'Bank of China Limited' },
  { label: 'Bank Islami Pakistan Limited', value: 'Bank Islami Pakistan Limited' },
  { label: 'Citi Bank N.A', value: 'Citi Bank N.A' },
  { label: 'Dubai Islamic Bank Pakistan', value: 'Dubai Islamic Bank Pakistan' },
  { label: 'Deutsche Bank AG', value: 'Deutsche Bank AG' },
  { label: 'First Women Bank Limited', value: 'First Women Bank Limited' },
  { label: 'Faysal Bank Limited', value: 'Faysal Bank Limited' },
  { label: 'Habib Bank Limited', value: 'Habib Bank Limited' },
  { label: 'Habib Metropolitan Bank Limited', value: 'Habib Metropolitan Bank Limited' },
  {
    label: 'Industrial and Commercial Bank of China',
    value: 'Industrial and Commercial Bank of China',
  },
  { label: 'JS Bank Limited', value: 'JS Bank Limited' },
  { label: 'Meezan Bank Limited	', value: 'Meezan Bank Limited	' },
  { label: 'MCB Bank Limited', value: 'MCB Bank Limited' },
  { label: 'MCB Islamic Bank Limited ', value: 'MCB Islamic Bank Limited ' },
  { label: 'National Bank of Pakistan', value: 'National Bank of Pakistan' },
  { label: 'Samba Bank Limited', value: 'Samba Bank Limited' },
  { label: 'Sindh Bank Limited', value: 'Sindh Bank Limited' },
  { label: 'SILKBANK Limited', value: 'SILKBANK Limited' },
  { label: 'Soneri Bank Limited', value: 'Soneri Bank Limited' },
  {
    label: 'Standard Chartered Bank Pakistan Limited',
    value: 'Standard Chartered Bank Pakistan Limited',
  },
  { label: 'Summit Bank Limited', value: 'Summit Bank Limited' },
  { label: 'The Bank of Khyber', value: 'The Bank of Khyber' },
  { label: 'The Bank of Punjab', value: 'The Bank of Punjab' },
  { label: 'United Bank Limited', value: 'United Bank Limited' },
];
